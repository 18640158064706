<template>
  <div class="process-box">
    <div class="new-process">
      <div class="step-box">
        <el-steps :active="stepActive">
          <!-- 基本设置，关键信息与小贴士，完成并启用  -->
          <el-step :title="$t('label.processsetting.basic.settings')">
            <svg class="icon"  slot="icon"   aria-hidden="true">
              <use :href="stepActive >= 1 ?'#icon-stepOneLan':'#icon-stepOneLan'"></use>
            </svg>
          </el-step>
          <el-step
            :title="$t('label.processsetting.key.information.tips')"
            class="tipinfo"
          >
             <svg class="icon"  slot="icon"  aria-hidden="true">
              <use :href="stepActive >= 2 ?'#icon-stepTwoLan':'#icon-stepTwoHui'"></use>
            </svg>
          </el-step>
          <el-step
            :title="$t('label.processsetting.complete.enable')"
            class="complete"
          >
            <svg class="icon"  slot="icon"  aria-hidden="true">
              <use :href="stepActive >= 3 ?'#icon-stepThreeLan':'#icon-stepThreeHui'"></use>
            </svg>
          </el-step>
        </el-steps>
      </div>
      <div class="step-content">
        <div v-if="stepActive == 1" class="step-one-box">
          <div class="title">
            <!-- 第一步：选择您要设置进程的对象与字段 -->
            {{ $t("label.processsetting.set.objandfile") }}
          </div>
          <el-form
            :inline="true"
            ref="basicSetForm"
            :model="BasicSetting"
            class="demo-form-inline"
            :rules="basicSetRules"
          >
            <el-form-item :label="$t('label.import.index.obj')" prop="object">
              <el-select
                filterable
                v-model="BasicSetting.object"
                @change="changeObjectList"
                :placeholder="$t('label.emailtocloudcc.select1')"
                :disabled="objid ? true : false"
              >
                <el-option
                  v-for="item in setObjectList"
                  :key="item.id"
                  :label="item.labelname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('label.tabpage.field')" prop="filed">
              <el-select
                filterable
                v-model="BasicSetting.filed"
                :disabled="objid ? true : false"
                :placeholder="$t('label.emailtocloudcc.select1')"
              >
                <el-option
                  v-for="item in setfieldList"
                  :key="item.id"
                  :label="item.labelname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="stepActive == 2" class="step-two-box" v-loading="loading">
          <div class="title">
            {{ $t("label.processsetting.two.select.key.fields") }}
            <!-- 第二步：为进程中的每个步骤选择关键字段，并提供指导 -->
          </div>
          <div class="stage">
            <div class="btn-box">
              <div
                v-for="(item, index) in stepData"
                v-show="index > switchStep"
                :key="index"
                class="btn-item"
              >
                <el-button
                  @click="chooseBtnActive(index, item)"
                  :class="btnActive == index ? 'btn-active' : 'btn'"
                  >{{ item.codevalue }}</el-button
                >
              </div>
            </div>

            <div class="switch-box">
              <el-button
                class="el-icon-caret-left"
                @click="reduceStep"
              ></el-button>
              <el-button
                @click="addStep"
                class="el-icon-caret-right"
              ></el-button>
            </div>
          </div>
          <div v-for="(item, index) in mainFieldData" :key="index">
            <div class="crux-filed" v-if="btnActive == index">
              <div class="filed">
                <!-- <el-input v-model="keyValue" disabled></el-input> -->
                <div class="info-header">
                  <span>{{keyValue}}</span>
                  <span class="set-file" @click="setShowShuttle">
                    {{ $t("label.processsetting.set.files") }}
                    <!-- 设置字段 -->
                  </span>
                </div>

                <div v-for="(item1, index1) in item.filed" :key="index1">
                  <el-input v-model="item1.labelName"></el-input>
                </div>
              </div>
              <div class="tips">
                <!-- <el-input v-model="tip" disabled></el-input> -->
                <div class="info-header">
                  <span>{{tip}}</span>
                </div>

                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 6 }"
                  :placeholder="$t('label.tabpage.contentz')"
                  v-model="item.tipsValue"
                  maxlength="255"
                  @input="changeValueNum"
                  resize="none"
                >
                </el-input>
                <div class="num-tip">
                  <!-- 最多输入255个字符，剩余数量： -->
                  {{ $t("label.processsetting.remaining.quantity") }}
                  {{ item.tipsValue ? 255 - item.tipsValue.length : "255" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="stepActive == 3" class="step-three-box">
          <div class="title">
            <!-- 第三步：启用 -->
            {{$t("label.processsetting.three.enable") }}
          </div>
          <div class="enable">
            <div class="desc">
              {{ $t("label.processsetting.enable.prompt") }}
              <!-- 启用后，设置的路径会在lightning版本对应的详情页中。也可先保存设置，稍后再启用。 -->
            </div>
            <el-switch v-model="enableflag"> </el-switch>
          </div>
        </div>
      </div>
      <el-dialog
        :title="$t('label.processsetting.set.files')"
        width="560px"
        :visible.sync="shuttleFlag"
        :before-close="handleClose"
        :close-on-click-modal="false"
        @click.native.capture="hideBtn"
      >
        <div class="table-head-tool">
          <oneShuttle
            ref="oneShuttle"
            :viewUnselectedFieldList="viewUnselectedFieldList"
            :viewSelectedFieldArr="viewSelectedFieldList"
            @selFun="getSelFun"
          />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <el-button type="primary" @click="saveField">{{
            $t("label.tabpage.ok")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="footer">
      <el-button v-if="stepActive == 1" @click="btnCancel">{{
        $t("label.emailsync.button.cancel")
      }}</el-button>
      <el-button
        v-if="stepActive == 3 || stepActive == 2"
        type="primary"
        @click="lastStep"
        >{{ $t("label.import.page1.laststep") }}
      </el-button>
      <el-button
        v-if="stepActive != 3"
        type="primary"
        class="next"
        @click="nextStep"
        >{{ $t("label.import.page1.nextstep") }}</el-button
      >
      <el-button
        type="primary"
        v-if="stepActive == 3"
        class="next"
        @click="saveProgressSetup"
        >{{ $t("label.emailtocloudcc.button.complete") }}</el-button
      >
    </div>
  </div>
</template>
  <script>
import oneShuttle from "@/components/ViewBox/oneShuttle.vue";
import {
  getObjectList,
  getSelectFieldList,
  getProgressSetupList,
  getProgressSelectValues,
  getProgressLayout,
  saveProgressSetup,
} from "@/views/systemSettings/systemSettingsApi.js";
export default {
  components: { oneShuttle },
  data() {
    return {
      BasicSetting: {
        object: this.$route.query.labelname || "", //对象
        filed: this.$route.query.fieldlabel || "", //字段
      },
      basicSetForm: {
        object: "", //对象
        filed: "", //字段
      },
      basicSetRules: {
        object: [
          {
            required: true,
            message: this.$i18n.t("vue_label_notice_pleaseselectobject"),
            trigger: "change",
          },
        ],
        filed: [
          {
            required: true,
            message: this.$i18n.t("label.processsetting.please.select.field"),
            trigger: "change",
          },
        ],
      },
      btnActive: 0, //选中的按钮第几个阶段
      viewSelectedFieldList: [], //已选字段
      viewUnselectedFieldList: [], //未选字段
      stepActive: 1, //步骤数
      shuttleFlag: false, //弹框开关
      enableflag: true, //第三步，启用按钮
      switchStep: -1, //控制阶段显示
      surplusNum: 255, //小贴士最大字数
      layoutListData: { layoutList: [] }, //保存阶段布局
      tip: this.$i18n.t("vue_label_commonobjects_detail_tips"), //小贴士
      keyValue: this.$i18n.t("vue_label_commonobjects_detail_key_field"), //关键字段vue_label_commonobjects_detail_key_field
      mainFieldData: [], //填写的关键字段和小贴士数据
      stepData: [], //根据对象下的不同字段，每个字段显示不同阶段列表
      setObjectList: [], //对象列表
      setfieldList: [], //字段列表
      loading: false,
      // 编辑的阶段索引列表
      editList: [0],
      // 跳转的对象信息
      jumpInfo: {},
      // 对象ID
      objid: this.$route.query.objid || "",
    };
  },
  created() {
    this.basicSetForm.object = this.$route.query.labelname || "";
    this.basicSetForm.filed = this.$route.query.fieldlabel || "";
    this.getObjectList();
  },
  mounted() {
    if (this.$route.query.fromDetail === "true") {
      // 从详情页进入
      this.getProgressSetupList();
    }
  },
  methods: {
    // 获取进程列表
    async getProgressSetupList() {
      let res = await getProgressSetupList();
      if (res.result) {
        // 筛选出objid=this.$route.query.objid的阶段
        let objid = this.$route.query.objid;
        let progressSetupList = res.data.filter(
          (item) => item.objid === objid
        );
        this.jumpInfo = progressSetupList[0];
        this.basicSetForm.object = this.jumpInfo.labelname || "";
        this.basicSetForm.filed = this.jumpInfo.fieldlabel || "";
        this.BasicSetting = {
          object: this.jumpInfo.labelname || "", //对象
          filed: this.jumpInfo.fieldlabel || "", //字段
        }
      }
    },
    //取消可选成员和可选字段组件的icon
    hideBtn() {
      if (this.$refs.oneShuttle !== undefined) {
        this.$refs.oneShuttle.hideBtn()
      }
      if (this.$refs.shuttles !== undefined) {
        this.$refs.shuttles.hideBtn()
      }
    },
    // 保存进度条设置信息
    async saveProgressSetup() {
      // codeid为阶段选项值id；layoutid为阶段布局id有的话必传，没有可以不传；
      // fieldIds为阶段布局选择的字段id，多个字段id用逗号分隔；tips为小贴士
      let jsonData = {};
      // 对数据进行筛选后，会出现关键字段和小贴士都为空时，请求接口不传递该阶段参数的问题，因此取消筛选
      jsonData.layoutList = this.layoutListData.layoutList;
      // jsonData.layoutList = this.layoutListData.layoutList.filter(
      //   (item, index) => {
      //     return item.fieldIds ||item.tips;
      //   }
      // );
      let tempArr = [];
      this.editList.map((item) => {
        let tempObj = jsonData.layoutList[item];
        tempArr.push(tempObj);
      });
      jsonData.layoutList = tempArr;
      let param = {
        id: this.$route.query.id || this.jumpInfo.id || "",//进度条id，编辑时必填
        objid: this.BasicSetting.object, //对象id
        fieldId: this.BasicSetting.filed, //是	string	字段id
        isusing: this.enableflag, //是	string	是否启用进度条，true 或 false
        json: JSON.stringify(jsonData), //阶段布局，格式示例：{“layoutList”:[{“codeid”:”2121212”,
      };
      let res = await saveProgressSetup(param);
      if (res.result) {
        this.$router.push({
          path: "processSettings",
        });
      }
    },
    // 2字段选择框，点击确定按钮，对已选字段进行处理，1、回显到输入框，、把id已逗号分隔进行拼接，调取接口
    saveField() {
      this.$refs.oneShuttle.curGroupLists();
      this.selectedFieldList = this.$refs.oneShuttle.viewSelectedFieldList;
      this.$nextTick(() => {
        if (this.selectedFieldList.length > 10) {
          // 最多可选择10个字段
          this.$message.warning(
            this.$i18n.t("c928")
          );
        } else {
          this.shuttleFlag = false; //关闭弹框
          // 清空mainFieldData
          this.mainFieldData[this.btnActive].filed = [];
          // 把选中的字段显示到字段输入框中,回显，拼接数据调取接口
          this.mainFieldData[this.btnActive].filed = this.selectedFieldList;
          let str = "";
          let arr = "";
          this.selectedFieldList.map((item) => {
            str = item.id;
            arr += str + ",";
          });
          this.layoutListData.layoutList[this.btnActive].fieldIds = arr;
        }
      });
    },
    //获取进度条设置对象列表
    async getObjectList() {
      let res = await getObjectList();
      this.setObjectList = res.data;
    },
    // 获取进度条对象下选项列表类型字段列表
    async getSelectFieldList(id) {
      let param = {
        id: id,
      };
      let res = await getSelectFieldList(param);
      this.setfieldList = res.data;
    },
    //获取进度条阶段列表值
    async getProgressSelectValues() {
      let param = {
        id: this.$route.query.fieldId ||this.jumpInfo.fieldId || this.BasicSetting.filed,
      };
      let res = await getProgressSelectValues(param);
      this.stepData = res.data;
      this.loading = false;
      this.getProgressLayout();
      this.stepData.map((item) => {
        //根据阶段的数量创建对应的填写框
        this.mainFieldData.push({
          layoutid: item.id,
          tipsValue: "",
        });

        this.layoutListData.layoutList.push({
          codeid: item.id,
          layoutid: item.layoutid,
          fieldIds: "",
          tips: item.tips,
        });
      });
    },
    // 对象变换获取对象下的字段
    changeObjectList(id) {
      this.getSelectFieldList(id);
      this.BasicSetting.filed = "";
    },
    // 获取进度条阶段布局,layoutid由接口返回，编辑时需要传layoutid获取已选字段
    async getProgressLayout() {
      this.viewSelectedFieldList = [];
      this.viewUnselectedFieldList = [];
      let param = {
        codeid: this.stepData[this.btnActive].id,
        layoutid: this.$route.query.objid
          ? this.stepData[this.btnActive].layoutid
          : "",
        objid:this.$route.query.objid || this.BasicSetting.object,
      };
      let res = await getProgressLayout(param);
      // 未选字段
      if (
        res.data.unselectedFieldList &&
        res.data.unselectedFieldList.length > 0
      ) {
        res.data.unselectedFieldList.filter((item) => {
          if (item.label != null) {
            item.labelName = item.label;
            this.viewUnselectedFieldList.push(item);
          }
        });
      }
      // 已选字段
      if (res.data.selectedFieldList && res.data.selectedFieldList.length > 0) {
        res.data.selectedFieldList.map((item) => {
          item.labelName = item.label;
        });
        if (this.$route.query.objid) {
          this.viewSelectedFieldArr = res.data.selectedFieldList;

          this.mainFieldData[this.btnActive].filed = res.data.selectedFieldList;
          let str = "";
          let arr = "";
          this.mainFieldData[this.btnActive].filed.map((item) => {
            str = item.id;
            arr += str + ",";
          });
          this.layoutListData.layoutList[this.btnActive].fieldIds = arr;
        }
      }
      if (this.$route.query.objid&&!this.mainFieldData[this.btnActive].tipsValue) {
        this.mainFieldData[this.btnActive].tipsValue = res.data.tips;
      }
      this.$forceUpdate();
    },
    // 小贴士内容，计算剩余字数，当前阶段赋值小贴士值
    changeValueNum(val) {
      let num = val.length;
      this.surplusNum = 255 - num;
      this.layoutListData.layoutList[this.btnActive].tips = val;
    },
    // 切换阶段按钮
    async chooseBtnActive(index) {
      this.btnActive = index;
      // 判断this.editList是否包含index,不存在的话，则添加
      if (!this.editList.includes(index)) {
        this.editList.push(index);
      }
      // 编辑时点击切换阶段，获取已选字段
      if (
        this.$route.query.objid &&
        this.layoutListData.layoutList[this.btnActive].fieldIds == ""
      ) {
        this.getProgressLayout();
      }
    },
    //获取可选成员
    getSelFun() {},
    // 向右切换阶段
    addStep() {
      if (this.switchStep < this.stepData.length - 4) {
        this.switchStep = this.switchStep + 1;
      }
      if (this.btnActive < this.stepData.length - 1) {
        this.btnActive = this.btnActive + 1;
        // 判断this.editList是否包含btnActive,不存在的话，则添加
        if (!this.editList.includes(this.btnActive)) {
          this.editList.push(this.btnActive);
        }
        if (
          this.$route.query.objid &&
          this.layoutListData.layoutList[this.btnActive].fieldIds == ""
        ) {
          this.getProgressLayout();
        }
      }
    },
    //向左切换阶段
    reduceStep() {
      if (this.switchStep >= 0) {
        this.switchStep = this.switchStep - 1;
      }
      if (this.btnActive > 0) {
        this.btnActive = this.btnActive - 1;
        // 判断this.editList是否包含btnActive,不存在的话，则添加
        if (!this.editList.includes(this.btnActive)) {
          this.editList.push(this.btnActive);
        }
        if (
          this.$route.query.objid &&
          this.layoutListData.layoutList[this.btnActive].fieldIds == ""
        ) {
          this.getProgressLayout();
        }
      }
    },
    // 设置字段按钮，显示选择字段弹框
    setShowShuttle() {
      this.shuttleFlag = true;
      // 
      // 
      // 当前阶段未设置字段清空已选字段
      if (
        this.viewSelectedFieldList &&
        !this.layoutListData.layoutList[this.btnActive].fieldIds
      ) {
        this.viewUnselectedFieldList.concat(this.viewSelectedFieldList);
        this.viewUnselectedFieldList.map((item) => {
          item.isselect = false;
        });
        this.viewSelectedFieldList = [];
      } else {
        this.viewSelectedFieldList = [];
        this.viewSelectedFieldList = this.mainFieldData[this.btnActive].filed;
        // viewSelectedFieldList与viewUnselectedFieldList去除中id重复的数据
        this.viewSelectedFieldList.map((item) => {
          item.isselect = false;
          this.viewUnselectedFieldList.map((item2) => {
            item2.isselect = false;
            if (item.id == item2.id) {
              this.viewUnselectedFieldList.splice(
                this.viewUnselectedFieldList.indexOf(item2),
                1
              );
            }
          });
        });
      }
      this.$refs.oneShuttle.initPage();
    },
    // 上一步
    lastStep() {
      if (this.stepActive == 2) {
        this.stepActive = 1;
      } else if (this.stepActive == 3) {
        this.stepActive = 2;
      }
    },
    // 下一步
    nextStep() {
      if (this.stepActive == 1) {
        this.$refs.basicSetForm.validate((valid) => {
          if (valid) {
            this.stepActive = 2;
          } else {
            return false;
          }
        });
        if (this.stepData.length == 0) {
          this.loading = true;
        }
        this.getProgressSelectValues();
      } else if (this.stepActive == 2) {
        this.stepActive = 3;
      }
    },
    // 取消按钮返回到进程设置页面
    btnCancel() {
      this.$router.push({
        path: "processSettings",
      });
      this.stepActive = 1;
    },
    //选择字段弹框取消按钮
    handleClose() {
      this.shuttleFlag = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.process-box {
  display: flex;
  height: 100%;
}
.btn-active {
  color: #fff;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  background: #006dcc;
  border: 1px solid #43a7fe;
  border-radius: 3px;
  width: 100%;
  padding: 10px 20px;
}
.new-process {
  padding: 30px 10px;
  width: 100%;
  overflow-y: auto;
  // height: 80%;
  .step-box {
    margin: 10px 15% 0;
    ::v-deep .el-step__icon.is-text {
      border: 0;
    }
    ::v-deep .el-step__title {
      font-size: 14px;
    }
    svg {
      width: 40px;
      height: 40px;
    }
    ::v-deep .el-step__icon {
      width: 50px;
    }
  }
  .step-content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 12px;
      color: #666666;
      margin-bottom: 20px;
    }
    .step-one-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      ::v-deep .el-form {
        display: flex;
        flex-direction: column;
      }
      ::v-deep .el-form-item {
        margin-bottom: 10px;
      }
      ::v-deep .el-form-item__error {
        padding-top: 0;
      }
      ::v-deep .el-form-item__label {
        width: 70px !important;
      }
      ::v-deep .el-input,
      ::v-deep .el-input__inner {
        font-size: 12px !important;
      }

      ::v-deep .el-select {
        min-width: 300px;
        height: 30px;
      }
      ::v-deep .el-input__icon,
      ::v-deep .el-select .el-input__inner {
        height: 30px;
        line-height: 30px;
      }
    }
    .step-two-box {
      display: flex;
      padding: 0px 15% 30px;
      flex-direction: column;
      .stage {
        display: flex;
        vertical-align: middle;
        align-items: center;
        .btn-box {
          display: flex;
          flex: 1;
          justify-content: space-between;
          overflow: hidden;

          .btn-item {
            min-width: 30%;
            margin-right: 4%;
          }
          .btn {
            font-size: 12px;
            width: 100%;
            padding: 10px 20px;
          }
        }

        .switch-box {
          width: 8%;
          margin-left: 2%;
          display: flex;
          ::v-deep .el-button {
            min-width: 25px;
            height: 25px;
            padding: 0;
          }
        }
      }
      .crux-filed {
        display: flex;
        ::v-deep .el-input__inner {
          height: 30px;
          line-height: 30px;
          margin-top: 16px;
          width: 95%;
          font-size: 12px;
        }
        .filed,
        .tips {
          width: 50%;
        }
        .set-file {
          font-size: 12px;
          color: #006dcc;
          // margin-top: 10px;
          cursor: pointer;
        }
        ::v-deep .el-textarea__inner {
          margin-top: 16px;
          width: 95%;
          height: 168px !important;
        }
        .num-tip {
          margin-top: 10px;
          font-size: 12px;
          color: #888888;
        }
      }
    }
    .step-three-box {
      margin: 0px 15% 0;
      display: flex;
      flex-direction: column;
      .enable {
        display: flex;
        margin-top: 10px;
      }
      .desc {
        font-size: 12px;
      }
      ::v-deep .el-switch__core {
        width: 25px !important;
        height: 15px;
      }
      ::v-deep .el-switch__core:after {
        width: 11px !important;
        height: 11px !important;
      }
      ::v-deep .el-switch.is-checked .el-switch__core::after {
        margin-left: -12px !important;
      }
    }
  }
}
.table-head-tool {
  ::v-deep .shu_left {
    height: 185px !important;
  }
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
    // width: calc(100% - 40px);
  }

  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }

  .dragBox {
    width: 49%;
  }

  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;

    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;

      i {
        position: absolute;
        z-index: 100;
      }

      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }

  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }

  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }

  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;

    &:hover {
      background: #fafaf9;
    }
  }

  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}

.footer {
  // border-top: 1px solid #dddbda;
  position: absolute;
  bottom: 5%;
  right: 15%;
  // width: calc(100% - 330px);
  text-align: right;
  height: 40px;
  ::v-deep .el-button {
    width: 74px;
    height: 28px;
    padding: 0;
    margin-top: 15px;
  }
  .next {
    // margin-right: 36px;
  }
}
.tipinfo {
  ::v-deep .el-step__title {
    margin-left: -32px;
  }
  ::v-deep .el-step__title.is-process {
    font-weight: normal;
    color: #191717;
  }
}
.complete {
  ::v-deep .el-step__title {
    margin-left: -10px;
  }
  ::v-deep .el-step__title.is-wait {
    color: #191717;
  }
}
.info-header{
  padding: 16px 25px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span{
    font-size: 12px;
    color: #666666;
  }
}
</style>
